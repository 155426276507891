<template>
  <div>
    <create
      v-if="craeteDialog"
      @createData="create($event)"
      @closePopup="closeCreate"
      :dialog="craeteDialog"
    />
    <edit
      v-if="editDialog"
      @editData="edit($event)"
      @closePopup="closeEdit"
      :dialog="editDialog"
      :dialogData="dialogData"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteManeger($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
    />
    <v-row>
      <v-col>
        <user-filter
          @getData="setDataFilter($event)"
          @clearData="setDataFilter({})"
        />
      </v-col>
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :loader="loader"
      :meta="pagination"
      :isLocalized="false"
    />
  </div>
</template>
<script>
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import userFilter from "./filter.vue";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import { Users } from "@/store/common/users/users";
export default {
  components: {
    dataTable,
    userFilter,
    deletePopup,
    create,
    edit,
  },
  data() {
    return {
      Users,
      dialogData: null,
      craeteDialog: false,
      editDialog: false,
      deleteDialog: false,
      deleteDialogData: null,
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.filterData, { page: page });
      Users.get(this.filterData);
    },
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    //create
    createAble() {
      this.craeteDialog = true;
      this.popupType = "create";
    },
    create(data) {
      Users.post(data);
      this.craeteDialog = false;
    },
    closeCreate() {
      this.craeteDialog = false;
    },

    //edit
    editAble(obj) {
      this.editDialog = true;
      this.dialogData = obj;
    },
    edit(data) {
      Users.edit(data.data, data.id);
      this.editDialog = false;
    },
    closeEdit() {
      this.editDialog = false;
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteManeger(id) {
      await Users.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    tableData() {
      return Users.tableData;
    },
    loader() {
      return Users.getState.loading;
    },

    pagination() {
      return Users.tableData.meta;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
