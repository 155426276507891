<template>
  <v-row>
    <v-col lg="2">
      <v-text-field
        v-model="data.username"
        :label="$t('serach-by-username')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-text-field
        v-model="data.email"
        :label="$t('serach-by-email')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-select
        v-model="data.type"
        :label="$t('search-by-user-type')"
        :items="types"
        item-value="value"
        item-text="name"
        outlined
      ></v-select>
    </v-col>
    <v-col lg="2">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.$emit("clearData");
    },
  },
  computed: {
    types() {
      return [
        {
          value: "manager",
          name: this.$t("managers"),
        },
        {
          value: "student",
          name: this.$t("students"),
        },
        {
          value: "teacher",
          name: this.$t("teachers"),
        },
      ];
    },
  },
};
</script>
